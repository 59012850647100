<template>
    <v-btn
        aria-label="Cart Menu"
        color="secondary"
        @click="emit('on-click', $event)"
        icon
    >
        <CartIcon></CartIcon>
    </v-btn>
</template>

<script lang="ts" setup>
    import CartIcon from '~/components/header/menus/CartIcon/CartIcon.vue';

    const emit = defineEmits<{
        'on-click': [value: Event];
    }>();
</script>

<style lang="scss"></style>
